import React from 'react';
import * as styles from './call.module.scss';
import { useTranslation } from "gatsby-plugin-react-i18next";

import ImgRelax from '../../../images/Contact/relax.png';
import ImgCall from '../../../images/Contact/call.png';
import ImgCoffe from '../../../images/Contact/coffe.png';

const Call = () => {

    const {t} = useTranslation();

	return (
		<section className={styles.callSection}>
			<div className={styles.containCall}>
				<div className={styles.containTitle}>
					<h2
					data-sal-delay='400'
					data-sal='slide-right'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
					{t("contact.call.letsTalk")}</h2>
					<p
					data-sal-delay='450'
					data-sal='slide-left'
				   	data-sal-duration='700'
				   	data-sal-easing='ease-out-back'>{t("contact.call.getInTouch")}</p>
				</div>
				<div className={styles.containAction}>
					{/* Card Coffe */}
					<div className={styles.containCard}
						data-sal-delay='500'
             			data-sal='zoom-in'
            			data-sal-duration='700'
            			data-sal-easing='ease-out-back'>
						<div className={styles.contDescriptionCard}>
							<div className={styles.contImg}>
								<img src={ImgCoffe} alt='imagen de referencia' />
							</div>
							<div className={styles.contDescription}>
								<h3>{t("contact.call.coffee.0")} <br /> {t("contact.call.coffee.1")}</h3>
								<p>{t("contact.call.coordinate")}</p>
							</div>
						</div>						
					</div>
					{/* Card Call */}
					<div className={styles.containCard}
						data-sal-delay='500'
             			data-sal='zoom-in'
            			data-sal-duration='700'
            			data-sal-easing='ease-out-back'>
						<div className={styles.contDescriptionCard}>
							<div className={styles.contImg}>
								<img src={ImgCall} alt='imagen de referencia' />
							</div>
							<div className={styles.contDescription}>
								<h3>{t("contact.call.callUs")}</h3> 
								<p>{t("contact.call.ourNumber")}</p>
								<span>+54 (011) 4071-3161</span>
								<p>{t("contact.call.startWorking")}</p>
							</div>
						</div>						
					</div>
					{/* Card Relax */}
					<div className={styles.containCard}
						data-sal-delay='500'
             			data-sal='zoom-in'
            			data-sal-duration='700'
            			data-sal-easing='ease-out-back'>
						<div className={styles.contDescriptionCard}>
							<div className={styles.contImg}>
								<img src={ImgRelax}  alt='imagen que representa el relax' />
							</div>
							<div className={styles.contDescription}>
								<h3>{t("contact.call.email")}</h3>
								<p>{t("contact.call.writeUs")}</p>
							</div>
						</div>						
					</div>
				</div>
			</div>
		</section>
	);
};
export default Call;
