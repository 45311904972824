// extracted by mini-css-extract-plugin
export var bodyHelp = "help-module--bodyHelp--63bec";
export var btn = "help-module--btn--37461";
export var btnLottie = "help-module--btnLottie--e5490";
export var contenerHelp = "help-module--contenerHelp--bff16";
export var detail = "help-module--detail--48d0d";
export var error = "help-module--error--e85a8";
export var fromContacto = "help-module--fromContacto--d3bd9";
export var fromLeft = "help-module--fromLeft--61932";
export var fromRight = "help-module--fromRight--ed3ff";
export var textArea = "help-module--textArea--80952";
export var textBox = "help-module--textBox--a9087";
export var title = "help-module--title--4c84f";