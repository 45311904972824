import React from "react";
import Layout from "../components/UI/layout";
import SEO from "../components/UI/seo";
import Call from "../components/Contact/Call/call";
import Help from "../components/Contact/Help/help";
import Maps from "../components/Contact/Maps/maps";
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next";


const ContactPage = () => {
  const {t} = useTranslation();
  return(
    <Layout>
      <SEO title="Codeicus | Contact" />
      <Call />
      <Help t={t}/>
      <Maps />  
    </Layout>
  )
}

export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;