import React from 'react';
import * as styles from './maps.module.scss';
import { useTranslation } from "gatsby-plugin-react-i18next";

const Maps = () => {

    const {t} = useTranslation();

	return (
		<section className={styles.mapsSection}>
            <div className={styles.containMaps}>

                <div className={styles.title}>
                    <h3
                    data-sal-delay='450'
					data-sal='slide-left'
				   	data-sal-duration='700'
				   	data-sal-easing='ease-out-back'>{t("contact.maps.where")}</h3>
                    <p
                    data-sal-delay='450'
					data-sal='slide-right'
				   	data-sal-duration='700'
				   	data-sal-easing='ease-out-back'>{t("contact.maps.address")}</p>
                </div>

                <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.0794118921467!2d-58.3970263849407!3d-34.602153380459896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccaeaa42fd9b1%3A0x6640930d6e0a22cc!2sCodeicus!5e0!3m2!1ses!2sar!4v1595171266507!5m2!1ses!2sar'
                    width='100%'
                    height=''
                    frameBorder='0'
                    allowFullScreen=''
                    title='codeicus'
                    data-sal-delay='500'
                    data-sal='zoom-in'
                    data-sal-duration='700'
                    data-sal-easing='ease-out-back'
                    />
			</div>
		</section>
	);
};
export default Maps;




